var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-auth-page',{attrs:{"title":"Give your employees flexible pay","subtitle":!_vm.isDisableRegistration
      ? 'Give your employees the flexibility to access, track, build and better manage their pay.'
      : '',"buttons":[
      !_vm.isDisableRegistration && {
        text: 'Register',
        link: '/register',
        style: {
          background: _vm.PaletteColors['brand-primary'],
          color: _vm.PaletteColors['base-white'],
          outline: 'none',
        },
      },
      {
        text: 'Book a demo',
        link: '/referral?demo',
        style: {
          background: _vm.PaletteColors['brand-primary'],
          color: _vm.PaletteColors['base-white'],
          outline: 'none',
        },
      },
      !_vm.isDisableLandingPageLinks && {
        text: 'Learn More →',
        link: 'https://wagestream.com/en',
      },
    ].filter(button => !!button),"showApContainer":false},scopedSlots:_vm._u([(!_vm.isDisablePartnerLogos)?{key:"footer",fn:function(){return [_c('span',{staticClass:"social__label"},[_vm._v("As used by our partners:")]),_c('img',{staticClass:"social__image",attrs:{"src":_vm.socialProofImg}})]},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }