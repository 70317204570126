<template>
  <app-auth-page
    title="Give your employees flexible pay"
    :subtitle="
      !isDisableRegistration
        ? 'Give your employees the flexibility to access, track, build and better manage their pay.'
        : ''
    "
    :image="themeImage('login-tablet.png')"
    :buttons="
      [
        !isDisableRegistration && {
          text: 'Register',
          link: '/register',
          style: {
            background: PaletteColors['brand-primary'],
            color: PaletteColors['base-white'],
            outline: 'none',
          },
        },
        !isDisableLandingPageLinks && {
          text: 'Learn More →',
          link: 'https://wagestream.com/en',
        },
      ].filter(button => !!button)
    "
  >
    <template v-if="!isDisablePartnerLogos" #footer>
      <span class="social__label">As used by our partners:</span>
      <img class="social__image" :src="socialProofImg" />
    </template>
    <app-loading v-if="loading" />
    <LoginForm v-else :image="themeImage('logo.svg')" />
    <template v-if="!isDisableRegistration">
      <div v-if="!loading" class="login__footer">
        <span>Don't have an account?</span>
        <router-link :to="{ name: 'register' }"> Register </router-link>
      </div>
    </template>
  </app-auth-page>
</template>

<script>
import { PaletteColors, themeImage } from '@/Theme';
import socialProofImg from '@/assets/login-social-proof.png';
import AppAuthPage from '@/components/AppAuthPage.vue';
import AppLoading from '@/components/AppLoading.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import LoginForm from '@/components/LoginForm.vue';

export default {
  name: 'Login',
  components: {
    AppAuthPage,
    AppLoading,
    LoginForm,
  },
  data() {
    return {
      loading: false,
      mfaId: undefined,
      socialProofImg,
      PaletteColors,
    };
  },
  setup() {
    const { isDisableLandingPageLinks, isDisablePartnerLogos, isDisableRegistration } = useFeatureFlags();

    return { isDisableLandingPageLinks, isDisablePartnerLogos, isDisableRegistration };
  },
  methods: {
    themeImage,
  },
  created() {},
};
</script>

<style scoped lang="scss">
.login {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;

  &__footer {
    margin-top: 24px;
    font-weight: 400;
    text-align: center;
    color: var(--palette-color-default-lighten-30);
  }

  &__logo {
    object-fit: contain;
    margin-bottom: 36px;
    height: 64px;
    width: 100%;
  }

  &__submit {
    margin-top: 24px;
    padding: 12px 16px;
    width: 100%;
  }

  &__recover {
    margin-top: -16px;
    text-align: right;
  }

  & .form-label {
    width: 100%;
    text-align: left;
    font-weight: 400;
    color: var(--palette-color-default-lighten-30);
  }
}

.social {
  margin-top: 42px;
  width: 100%;

  &__label {
    display: inline-block;
    margin-top: 24px;
    font-size: 12px;
    width: 100%;
  }

  &__image {
    width: 95%;
  }
}
</style>
