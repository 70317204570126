<template>
  <app-page
    title="Banking Mismatch Update"
    :previous="[
      { label: 'Employees', to: { name: 'employees' } },
      {
        label: employee ? employee.full_name : 'Employee',
        to: { name: 'employee', params: { employeeId: $route.params.employeeId } },
      },
    ]"
  >
    <app-loading :loading="loading" />
    <template v-if="!loading">
      <sme-alert level="warning" class="mb-3">
        Please update your payroll details with the following {{ bankingInfo.sortCode.label }} and
        {{ bankingInfo.accountNumber.label }}.
      </sme-alert>

      <app-input
        v-model="employee.full_name"
        type="text"
        name="employee name"
        label="Employee Name"
        readonly
      ></app-input>
      <app-input
        v-model="employee.employee_code"
        type="text"
        name="employee code"
        label="Employee Code"
        readonly
      ></app-input>
      <app-input
        v-model="employee.properties.payment_sort_code"
        type="text"
        name="sort code"
        readonly
        :label="bankingInfo.sortCode.label"
      ></app-input>

      <app-input
        v-model="employee.properties.payment_account_number"
        type="text"
        name="account number"
        readonly
        :label="bankingInfo.accountNumber.label"
      ></app-input>

      <ValidationObserver v-slot="v">
        <b-form @submit.prevent="v.handleSubmit(onSubmit)">
          <ValidationProvider name="updated" :rules="{ required: { allowFalse: false } }">
            <b-form-checkbox v-model="updated">I have updated this employee in my company payroll file</b-form-checkbox>
          </ValidationProvider>
          <page-sub-footer>
            <b-button type="submit" variant="primary" class="mt-3" :disabled="v.invalid"> Confirm </b-button>
          </page-sub-footer>
        </b-form>
      </ValidationObserver>
    </template>
  </app-page>
</template>

<script setup>
import ApiClient from '@/ApiClient';
import AppPage from '@/components/AppPage.vue';
import AppInput from '@/components/AppInput.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import AppLoading from '@/components/AppLoading.vue';
import { getBankingInfo } from '@/Banking';

import { ref, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router/composables';

const $route = useRoute();

// const employeeId = this.$route.params.employeeId
const employee = ref();
const loading = ref(true);
const bankingInfo = getBankingInfo();

const loadData = async () => {
  loading.value = true;
  employee.value = await ApiClient.getEmployee($route.params.employeeId);
  loading.value = false;
};
onBeforeMount(async () => await loadData());

const onSubmit = async () => {
  const newEmployee = {
    ...employee.value,
    integration_properties: {
      ...employee.value.integration_properties,
      personal_sort_code: employee.value.properties.payment_sort_code,
      personal_account_number: employee.value.properties.payment_account_number,
    },
  };
  await ApiClient.updateEmployee(newEmployee);
};
</script>
