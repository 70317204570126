<template>
  <div class="recent-uploads-list">
    <page-list-header>
      <template #left>
        <inline-search v-model="searchText" @search="getFiles" />
        <filter-dropdown v-model="filters" :filters="filterItems" />
      </template>
      <template #right>
        <b-form-group label-cols="auto" label="Per page" class="mb-0">
          <b-form-select v-model="perPage" :options="PER_PAGE_OPTIONS" />
        </b-form-group>
      </template>
    </page-list-header>
    <div class="mb-3">
      <app-loading :loading="loading" />
      <b-table
        striped
        hover
        :items="files"
        :fields="['name', 'uploaded_at', 'uploaded_by', 'is_processed', 'processed_at']"
      >
        <template #cell(name)="row">
          {{ row.item.filename }}
        </template>
        <template #cell(uploaded_at)="row">
          {{ parseTimestamp(row.item.uploaded_at) }}
        </template>
        <template #cell(uploaded_by)="row">
          {{ row.item.username }}
        </template>
        <template #cell(is_processed)="row">
          <font-awesome-icon v-if="row.item.is_processed" :icon="['fal', 'circle-check']" class="success-icon" />
          <font-awesome-icon v-else :icon="['fal', 'circle-xmark']" class="failure-icon" />
        </template>
        <template #cell(processed_at)="row">
          {{ row.item.processed_at && parseTimestamp(row.item.processed_at) }}
        </template>
      </b-table>
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="justify-content-end" />
    </div>
  </div>
</template>

<script setup>
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading.vue';
import InlineSearch from '@/components/InlineSearch.vue';
import PageListHeader from '@/components/PageListHeader.vue';
import { PER_PAGE_OPTIONS } from '@/pages/absences/constants';
import FilterDropdown from '@/components/FilterDropdown.vue';
import { CONTROL_TYPES } from '@/components/AppInput.vue';

import { computed, onBeforeMount, ref, watch } from 'vue';

const files = ref([]);
const resp = ref();
const loading = ref(true);
const searchText = ref('');
const currentPage = ref(1);
const totalRows = ref(0);
const perPage = ref(25);
const filters = ref({});

const filterItems = computed(() => [
  {
    key: 'is_processed',
    label: 'Processing Status',
    type: CONTROL_TYPES.SELECT,
    options: [
      { text: 'Processed', value: 'true' },
      { text: 'Not Processed', value: 'false' },
    ],
  },
]);

const getFiles = async () => {
  loading.value = true;
  files.value = [];
  const params = {
    page: currentPage.value,
    limit: perPage.value,
    search_text: searchText.value,
    is_processed: filters.value.is_processed,
  };
  resp.value = await ApiClient.getLastestBrowserUploads(params);
  files.value = resp.value?.data || [];
  totalRows.value = resp.value?.total || 0;
  loading.value = false;
};

const parseTimestamp = timeStamp => {
  return new Date(timeStamp).toLocaleString('en-GB', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

watch([() => currentPage.value, () => perPage.value, () => filters.value.is_processed], () => getFiles(), {
  deep: true,
});

onBeforeMount(async () => await getFiles());
</script>

<style lang="scss" scoped>
.success-icon {
  color: var(--palette-color-success);
  font-size: large;
}

.failure-icon {
  color: var(--palette-color-danger);
  font-size: large;
}
.recent-uploads-list {
  margin-top: 2em;
}
</style>
