export const TODO_ACTIONS = {
  BILLING_SETUP: 'billing.setup',
  SUBSCRIPTION_SETUP: 'subscription.setup',
  SUBSCRIPTION_PENDING: 'subscription.pending',
  KYC_PENDING: 'kyc.pending',
  ADD_EMPLOYEES: 'add.employees',
  SETUP_EMPLOYEE: 'employee.setup',
  CHECK_EMPLOYEE_BANKING_MISMATCH: 'employee.checkbankingmismatch',
  DISABLE_EMPLOYEE: 'employee.revertbanking',
  FUND_PENDING: 'fund.pending',
  CONFIGURE_INTEGRATION: 'configure.integration',
  CONFIGURE_PAYSCHEDULE: 'configure.payschedule',
  UPDATE_PAYSCHEDULE: 'update.payschedule',
  SEND_INVITES: 'send.invites',
  SALARY_SIGN_OFF: 'confirm.salarysignoff',
  ACCEPT_TERMS: 'accept.terms',
  CONFIRM_UPCOMING_PAYSCHEDULE: 'confirm.payschedule',
};

export const ONBOARDING_TODO_ACTIONS = [
  TODO_ACTIONS.BILLING_SETUP,
  TODO_ACTIONS.SUBSCRIPTION_SETUP,
  TODO_ACTIONS.KYC_PENDING,
  TODO_ACTIONS.ADD_EMPLOYEES,
  TODO_ACTIONS.FUND_PENDING,
  TODO_ACTIONS.CONFIGURE_INTEGRATION,
  TODO_ACTIONS.CONFIGURE_PAYSCHEDULE,
  TODO_ACTIONS.SEND_INVITES,
  TODO_ACTIONS.ACCEPT_TERMS,
];

export const ONBOARDING_TODO_ACTIONS_COUNT = ONBOARDING_TODO_ACTIONS.length;
