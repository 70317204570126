import { computed } from 'vue';
import ApiClient from '@/ApiClient';
import useFeatureFlags from '@/composables/useFeatureFlags';
import State from '@/state/State';
import { useStateComposable } from '@/state/composables';
import { getLaunchStatus, LAUNCH_STATUS } from '@/utils/LaunchState';
import { ONBOARDING_TODO_ACTIONS, ONBOARDING_TODO_ACTIONS_COUNT, TODO_ACTIONS } from '@/utils/Todo.js';

const {
  canEnrollEmployees,
  canDisableEmployees,
  isSubscription,
  isKYC,
  isSelfFunded,
  canAddEmployees,
  canIntegrate,
  canUpdatePayschedule,
  canLaunchSelf,
  canSalarySignOff,
  canSignTerms,
  canUpdateBanking,
} = useFeatureFlags();

// filter out todos that this company can't do, according to their feature flags
const todoControl = {
  [TODO_ACTIONS.SUBSCRIPTION_SETUP]: isSubscription,
  [TODO_ACTIONS.KYC_PENDING]: isKYC,
  [TODO_ACTIONS.FUND_PENDING]: isSelfFunded,
  [TODO_ACTIONS.SETUP_EMPLOYEE]: canEnrollEmployees,
  [TODO_ACTIONS.ADD_EMPLOYEES]: canAddEmployees,
  [TODO_ACTIONS.CHECK_EMPLOYEE_BANKING_MISMATCH]: canUpdateBanking,
  [TODO_ACTIONS.DISABLE_EMPLOYEE]: canDisableEmployees,
  [TODO_ACTIONS.CONFIGURE_INTEGRATION]: canIntegrate,
  [TODO_ACTIONS.CONFIGURE_PAYSCHEDULE]: canUpdatePayschedule,
  [TODO_ACTIONS.SALARY_SIGN_OFF]: canSalarySignOff,
  [TODO_ACTIONS.SEND_INVITES]: canLaunchSelf,
  [TODO_ACTIONS.ACCEPT_TERMS]: canSignTerms,
};

const { computedState, state, reset } = useStateComposable({
  isSoftLaunch: false,
  launchStatus: {},
  outstandingTodos: [],
  status: LAUNCH_STATUS.YET_TO_LAUNCH,
  error: false,
  loading: false,
  initial: true,
});

const outstandingTodosCount = computed(() => state.outstandingTodos.length);
const percentageOnboarded = computed(() => {
  const numberIncomplete = ONBOARDING_TODO_ACTIONS.reduce(
    (count, todoAction) => (hasTodoAction(todoAction) ? ++count : count),
    0,
  );
  return parseInt(((ONBOARDING_TODO_ACTIONS_COUNT - numberIncomplete) / ONBOARDING_TODO_ACTIONS_COUNT) * 100);
});
const isActivating = computed(() => state.status === LAUNCH_STATUS.ACTIVATING);
const isBusinessAsUsual = computed(() => state.status === LAUNCH_STATUS.BUSINESS_AS_USUAL);
const isOnboarded = computed(() => percentageOnboarded.value >= 100);
const isReadyToLaunch = computed(() => state.launchStatus.can_soft_launch);
const isYetToHardLaunch = computed(() => !state.launchStatus.has_launched || !state.launchStatus.can_launch);
const isYetToLaunch = computed(() => !state.launchStatus.has_launched);

const getLaunchState = async (fetchOnlyIfInitial = false) => {
  if (fetchOnlyIfInitial && !state.initial) {
    return;
  }

  state.initial = false;

  // if doing a refetch, reset all state except initial so that we can fetch cached data if needed
  reset(['isSoftLaunch', 'launchStatus', 'outstandingTodos', 'status', 'error', 'loading']);

  state.loading = true;

  try {
    await ApiClient.getUser();
    state.canAutoEnroll = await State.getAutoEnroll();
    state.isSoftLaunch = State.state.company.properties.is_soft_launch;
    state.launchStatus = (await ApiClient.getCompanyLaunchStatus(State.state.company.company_id)) || {};
    const filteredTodos = State.state.todos.filter(todo => todoControl[todo.action]?.value);
    state.outstandingTodos = filteredTodos;

    state.status = getLaunchStatus(State.state.company.properties.launch_date);

    if (isYetToLaunch.value && canLaunchSelf.value) {
      state.outstandingTodos.push({
        action: TODO_ACTIONS.SEND_INVITES,
        disabled: !isReadyToLaunch.value,
      });
    }
  } catch (error) {
    state.error = true;
    console.error(error);
  }

  state.loading = false;
};

const hasTodoAction = todoAction =>
  state.outstandingTodos.find(outstandingTodo => outstandingTodo.action === todoAction);

export default () => {
  return {
    ...computedState,
    outstandingTodosCount,
    percentageOnboarded,
    isActivating,
    isBusinessAsUsual,
    isOnboarded,
    isReadyToLaunch,
    isYetToHardLaunch,
    isYetToLaunch,
    getLaunchState,
    hasTodoAction,
  };
};
