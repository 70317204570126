var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-auth-page',{attrs:{"title":"Give your employees flexible pay","subtitle":!_vm.isDisableRegistration
      ? 'Give your employees the flexibility to access, track, build and better manage their pay.'
      : '',"image":_vm.themeImage('login-tablet.png'),"buttons":[
      !_vm.isDisableRegistration && {
        text: 'Register',
        link: '/register',
        style: {
          background: _vm.PaletteColors['brand-primary'],
          color: _vm.PaletteColors['base-white'],
          outline: 'none',
        },
      },
      !_vm.isDisableLandingPageLinks && {
        text: 'Learn More →',
        link: 'https://wagestream.com/en',
      },
    ].filter(button => !!button)},scopedSlots:_vm._u([(!_vm.isDisablePartnerLogos)?{key:"footer",fn:function(){return [_c('span',{staticClass:"social__label"},[_vm._v("As used by our partners:")]),_c('img',{staticClass:"social__image",attrs:{"src":_vm.socialProofImg}})]},proxy:true}:null],null,true)},[(_vm.loading)?_c('app-loading'):_c('LoginForm',{attrs:{"image":_vm.themeImage('logo.svg')}}),(!_vm.isDisableRegistration)?[(!_vm.loading)?_c('div',{staticClass:"login__footer"},[_c('span',[_vm._v("Don't have an account?")]),_c('router-link',{attrs:{"to":{ name: 'register' }}},[_vm._v(" Register ")])],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }